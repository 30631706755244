export const PHONE_HAS_BIND_USERS = -2147467248

export const PHONE_HAS_NOT_BIND_USERS = -2147463156

export const PHONE_HAS_NOT_BIND_SELF = -2147467246

export const E_PHONE_HAS_BIND_CANNOT_MERGE = -2147467242

export const INVALID_PARAMETERS = -2147463167

export const ACCOUNT_OR_PASSWORD_ERROR = -2147463163

export const IMAGE_CODE_ERROR = -2004318073

export const IMAGE_CODE_INVALID = -2004318072

export const SMS_CODE_ERROR = -2004318072

export const SMS_AUTH_ERROR = -2130706425

export const SMS_CODE_EXPIRED = -2147446783

export const TOKEN_DEPRECATED = -2004318064

export const PAY_CHANEL_DIFFERENT = -1438646260
export const PAY_COUPON_USER_DONT_HAVE = 0x67000506
export const PAY_COUPON_THRESHOLD_NOT_MET = 0x67000509
export const PAY_COUPON_DONT_HAVE_USABLE = 0x67000510
export const PAY_COUPON_UN_OVERLAY = 0x67000511
export const PAY_ORDER_REPEAT = -1438646259

export const AUTH_LOGIN_NEED_VALIDATION = -1593675776

export const EXPORT_REPEAT_ERROR = -1343217663

export const INVALID_CODE_ADD = -1593671671
export const NO_BALANCE_CODE = -1438646269

export const COMBO_EMPLOYEE_COUNT_EXCEED = -1593774080

export const PAY_CHANEL_DIFFERENT_MSG = '请与下单时的支付方式保持一致'

export const QUESTION_ASK_TOO_MANY = -1593634816

export const QUIZ_NOT_GROUPED = -1507815402

export const CLASS_INVALID = -1593671675

export const REMOTE_SERVICE_FAILED = -2147479549

export const NOT_ALLOW_UPDATE = 1728054018
