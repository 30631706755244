import { CLIENT_TYPE } from '@shared/constants/client'
import type { ApiUserInfo } from '@shared/constants/user'
import type { ApiCacheOptions } from '@shared/util/request'
import { ApiOptions, getRequestWithCache, postRequest } from '..'

export const getUserInfo = (cacheOptions: ApiCacheOptions = {}) => {
  return getRequestWithCache<ApiUserInfo>(
    new ApiOptions({
      url: '/net/api/account/info',
    }),
    cacheOptions,
  )
}

interface AnonymousResponse {
  token: string
  key: string
  userId: number
}
export const createAnonymous = ({ deviceToken }: { deviceToken: string }) => {
  return postRequest<AnonymousResponse>(new ApiOptions({
    url: '/net/api/account/createAnonymous',
    args: { deviceToken, clientType: CLIENT_TYPE.PcWeb },
  }))
}
